import { Flex, Typography } from 'antd';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { CatalogGroupTreeWidget } from 'widgets/Catalogs/CatalogGroupTreeWidget';
import { Transactions } from 'widgets/Transactions';
import { AddCatalogRecordUi } from 'features/catalogs/CatalogRecords/AddCatalogRecord';
import { ToggleSubordinatedCatalogsRecords } from 'features/catalogs/CatalogRecords/ToggleSubordinatedCatalogsRecords';
import { ImportCatalogRecord } from 'features/catalogs/importCatalogRecord';
import { useAttributesDeclarations } from 'entities/catalogs/attributeDeclarations';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { useCatalogRecords } from 'entities/catalogs/catalogRecords';
import {
	ICatalogRecords,
	useRecordsDtoToTableMapper,
} from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { routes } from 'shared/config';
import { renderContent, RenderElementType } from 'shared/helpers/renderContent';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { ChartGantt, Search, setSubMenuCollapsed, WorkTable } from 'shared/ui';
import {
	Calendar,
	ICalendarItem,
	IChartGanttItem,
	IconButton,
	SubSider,
} from 'shared/ui/components';
import { PeriodSelector } from 'shared/ui/components/ChartGantt/PeriodSelector';
import { CatalogItemsViewType, useCatalogViewType } from './useCatalogViewType';

export const CatalogPage: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { catalogRecordsViewType, viewTypesButtonFactory } = useCatalogViewType();
	const { catalogList, loading: catalogListLoading, selectedCatalog } = useCatalogGroups();
	const { attributes } = useAttributesDeclarations();
	const {
		catalogGroupId,
		catalogRecordsDataSource,
		catalogRecordsColumns,
		catalogRecordError,
		catalogRecordLoading,
		searchHandler,
		searchValue,
		pagination,
		handleTableChange,
	} = useCatalogRecords();
	const { mapRecordsDtoToTable, isItemLoading } = useRecordsDtoToTableMapper();

	const [ catalogRecordsTableRows, setCatalogRecordsTableRows ] = useState<ICatalogRecords | null>(
		null,
	);
	const [ ganttData, setGanttData ] = useState<IChartGanttItem[] | null>(null);
	const [ ganttMoments, setGanttMoments ] = useState<[ Moment, Moment ]>();
	const [ calendarData, setCalendarData ] = useState<any>(null);

	const isCatalogDataExists = catalogRecordsColumns?.length && catalogRecordsTableRows?.length;
	const isCatalogGroupsEmpty = !catalogList?.length && !selectedCatalog && !catalogListLoading;
	const isCatalogNotSelected =
		catalogList?.length !== 0 && !selectedCatalog && !catalogListLoading;

	const { t } = useTypedTranslation();

	useEffect(() => {
		if (catalogRecordsDataSource && catalogRecordsColumns) {
			mapRecordsDtoToTable(catalogRecordsDataSource)
				.then((res) =>
					setCatalogRecordsTableRows(res),
				);
		}

		if (catalogRecordsDataSource && attributes) {

			if (attributes[0]?.catalogId !== catalogGroupId) {
				return;
			}

			const dateTimeAttributes = attributes
				.filter(x => x.attribute.type === 'DATE_TIME');

			if (dateTimeAttributes.length === 1) {
				setCalendarData(catalogRecordsDataSource
					.map<ICalendarItem>(x => {
						const date = (x.values[dateTimeAttributes[0].id] as any)?.value;
						return {
							id: x.id,
							title: x.displayName,
							date: date,
						};
					})
				);
			} else if (dateTimeAttributes.length === 2) {
				setGanttData(
					catalogRecordsDataSource
						.map<IChartGanttItem>((x) => {
							const date1 = (x.values[dateTimeAttributes[0].id] as any)?.value;
							const date2 = (x.values[dateTimeAttributes[1].id] as any)?.value;
							const dates = [
								...date1 ? [ moment(date1) ] : [],
								...date2 ? [ moment(date2) ] : [],
							];
							return {
								id: x.id,
								title: x.displayName,
								dateFrom: moment.min(dates).startOf('day'),
								dateTo: moment.max(dates).endOf('day'),
							};
						})
						.filter((x) => !!x.dateFrom && !!x.dateTo),
				);
			}
		}
	}, [ catalogRecordsDataSource, catalogRecordsColumns, attributes ]);

	useEffect(() => {
		setCalendarData(null);
		setGanttData(null);
	}, [ catalogGroupId ]);

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	const onChangeMoments = useCallback(
		(startMoment: Moment, endMoment: Moment) => setGanttMoments([ startMoment, endMoment ]),
		[],
	);

	const activeCatalogRecordsViewTypes = useMemo(() => {
		return [
			viewTypesButtonFactory(CatalogItemsViewType.Table),
			...(ganttData != null
				? [ viewTypesButtonFactory(CatalogItemsViewType.Gantt) ]
				: []),
			...(calendarData != null
				? [ viewTypesButtonFactory(CatalogItemsViewType.Calendar) ]
				: []),
		];
	}, [ ganttData, calendarData, viewTypesButtonFactory ]);

	const onClickItem = useCallback(
		(id: string | number, rowCatalogId?: string | number | undefined) =>
			navigate(`/${routes.catalogs.main}/${rowCatalogId || catalogGroupId}/record/${id}`),
		[ navigate ]
	);

	const contentFactory = (): RenderElementType => {
		if (isCatalogGroupsEmpty) {
			return {
				placeholder: {
					title: t(l => l.catalogs.groups.noGroups),
				},
			};
		} else if (isCatalogNotSelected) {
			return {
				placeholder: {
					title: t(l => l.catalogs.groups.selectGroup),
				},
			};
		} else if (catalogRecordError) {
			return {
				error: {
					title: t(l => l.catalogs.records.recordsListErr),
					subTitle: JSON.stringify(catalogRecordError),
				},
			};
		} else if (selectedCatalog && !catalogRecordError) {
			if (!isCatalogDataExists && !catalogRecordLoading && !isItemLoading) {
				return {
					placeholder: {
						title: searchValue
							? t(l => l.common.search.nothingFound)
							: t(l => l.catalogs.records.noRecords),
						subTitle: searchValue
							? t(l => l.common.search.checkRequest)
							: t(l => l.catalogs.records.addRecordTooltip),
						isSearchIcon: !!searchValue,
					},
				};
			}

			if (catalogRecordsViewType == CatalogItemsViewType.Table) {
				if (catalogRecordLoading || isItemLoading) {
					return;
				}

				return {
					content: (
						<WorkTable
							columns={catalogRecordsColumns}
							dataSource={catalogRecordsTableRows}
							loading={catalogRecordLoading || isItemLoading}
							noFilters={true}
							onRow={(r) => ({
								onClick: () => onClickItem(r.id, r.catalogId),
							})}
							scroll={{ x: 'max-content' }}
							onChange={(pagination) => handleTableChange(pagination)}
							pagination={pagination}
						/>
					),
				};
			} else if (catalogRecordsViewType == CatalogItemsViewType.Gantt) {
				if (ganttData == null) {
					return;
				}

				return {
					content: <div>
						<PeriodSelector onChangeMoments={onChangeMoments}/>
						{
							ganttMoments && ganttMoments[0] && ganttMoments[1] &&
                            <ChartGantt data={ganttData}
                                        startMoment={ganttMoments[0]}
                                        endMoment={ganttMoments[1]}
                                        onClickItem={onClickItem}/>}
					</div>,
				};
			} else if (catalogRecordsViewType == CatalogItemsViewType.Calendar) {
				if (calendarData == null) {
					return;
				}

				return {
					content: <Calendar data={calendarData} onClickItem={onClickItem} />,
				};
			}
		}
	};

	return (
		<>
			<SubSider>
				<CatalogGroupTreeWidget/>
			</SubSider>

			<Flex vertical gap={24}>
				<Flex justify="space-between" align="top">
					<Typography.Title level={1}>
						{selectedCatalog && selectedCatalog.displayName}
					</Typography.Title>

					<Transactions/>
				</Flex>

				{selectedCatalog && (
					<>
						<Search onChange={searchHandler} defaultValue={searchValue}/>
						<Flex gap={4} align="center">
							<AddCatalogRecordUi/>
							<ImportCatalogRecord/>

							<Flex gap={8} align="center" style={{ marginLeft: 'auto' }}>
								<ToggleSubordinatedCatalogsRecords/>

								{activeCatalogRecordsViewTypes.length > 1 && (
									<Flex gap={4}>
										{activeCatalogRecordsViewTypes.map((item) => (
											<IconButton
												key={item.type}
												Icon={item.icon}
												active={catalogRecordsViewType === item.type}
												onClick={item.onClick}
											/>
										))}
									</Flex>
								)}
							</Flex>
						</Flex>
					</>
				)}


				{
					renderContent(contentFactory)
				}
			</Flex>
		</>
	);
};
