import { App } from 'antd';
import { editCurrentCatalog } from 'entities/catalogs/catalogGroups/catalog.store';
import { useTransactions } from 'entities/transactions';
import { CatalogDto, useUpdateCatalogMutation } from 'shared/api/generatedApi/mdmgApi';
import { UpdateCatalogRequest } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useEditCatalogMainInfo = () => {
	const [updateCatalogInfo] = useUpdateCatalogMutation();
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();
	const { getTransactions } = useTransactions();

	const handleEditInfo = async (body: UpdateCatalogRequest, currentCatalog: CatalogDto) => {
		if (!body.displayName) {
			errorHelper('Поле Наименование должно быть заполнено!', new Error(''), notification);
			dispatch(editCurrentCatalog(currentCatalog));
			return;
		}
		const data = await updateCatalogInfo({
			id: currentCatalog.id,
			updateCatalogRequest: {
				displayName: body.displayName,
				description: body.description,
				displayNameAttributeDeclarationId: body.displayNameAttributeDeclarationId,
			},
		});
		if ('error' in data) {
			errorHelper('Ошибка при редактировании справочника!', data.error, notification);
			dispatch(editCurrentCatalog(currentCatalog));
		}
		if ('data' in data) {
			const newCurrentCatalog = {
				...currentCatalog,
				displayName: body.displayName,
				description: body.description,
				id: currentCatalog.id,
				displayNameAttributeDeclarationId: body.displayNameAttributeDeclarationId,
			};
			dispatch(editCurrentCatalog(newCurrentCatalog));
			getTransactions();
		}
	};

	return {
		handleEditInfo,
	};
};
