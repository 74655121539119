import { App } from 'antd';
import { editClassifierAttributeDeclaration } from 'entities/classifiers/classifierAttributeDeclarations/attributeDeclarations.store';
import {
	ClassifierAttributeDeclarationDto,
	useUpdateClassifierAttributeDeclarationMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { ItemValues } from 'shared/helpers/types';
import { useAppDispatch } from 'shared/hooks';

export const useSetupClassifierAttribute = () => {
	const [addUpdateEvent4, { isLoading }] = useUpdateClassifierAttributeDeclarationMutation();
	const dispatch = useAppDispatch();
	const { notification } = App.useApp();

	const editData = async (classifier: ClassifierAttributeDeclarationDto, v: ItemValues) => {
		await addUpdateEvent4({
			id: classifier.id,
			updateClassifierAttributeDeclarationRequest: {
				measurementId: classifier.measurementId,
				restrictions: { ...(v as unknown as object) },
			},
		})
			.unwrap()
			.then((res) => {
				dispatch(
					editClassifierAttributeDeclaration({
						...classifier,
						restrictions: {
							...classifier.restrictions,
							...res.restrictions,
						},
					})
				);
			})
			.catch((e) => {
				errorHelper(
					'Ошибка при редактировании декларации атрибута классификатора!',
					e,
					notification
				);
			});
	};

	return {
		editData,
		isLoading,
	};
};
