import { Flex, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { ClassifierItemsTable } from 'widgets/Classifiers/ClassifierItems/ClassifierItemsTable';
import { ClassifierItemTreeWidget } from 'widgets/Classifiers/ClassifierItems/ClassifierItemsTree';
import { Transactions } from 'widgets/Transactions';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { removeClassifierAttributeDeclarationList } from 'entities/classifiers/classifierAttributeDeclarations/attributeDeclarations.store';
import { useGetCurrentClassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.model';
import { removeCurrentClassifierGroup } from 'entities/classifiers/classifiersGroups/classifier.store';
import { removeClassifierItems } from 'entities/classifiers/classifiersItems/classifierItems.store';
import { routes } from 'shared/config';
import { useAppDispatch } from 'shared/hooks';
import { SubSider } from 'shared/ui/components/SubSider';

const ClassifierListPageUi: React.FC = () => {
	const { currentClassifierGroup, loading } = useGetCurrentClassifierGroup();

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(
			setBreadcrumbs({
				displayName: 'Перечень классификаторов',
				url: `${routes.classifiers.main}`,
				child: {
					displayName: 'Перечень записей классификатора',
					url: `/${routes.classifiers.main}/${currentClassifierGroup?.id}/${routes.classifiers.records}`,
				},
			})
		);

		return () => {
			dispatch(removeClassifierItems());
			dispatch(removeCurrentClassifierGroup());
			dispatch(removeClassifierAttributeDeclarationList());
		};
	}, []);

	return (
		<div>
			{loading && (
				<Flex vertical align="center" justify="center">
					<Spin size="large" />
				</Flex>
			)}
			{currentClassifierGroup && (
				<Flex vertical gap={24}>
					<Flex justify="space-between">
						<Typography.Title level={1}>
							{currentClassifierGroup?.displayName}
						</Typography.Title>
						<Transactions />
					</Flex>
					<SubSider>
						<ClassifierItemTreeWidget />
					</SubSider>

					<ClassifierItemsTable />
				</Flex>
			)}
		</div>
	);
};

export const ClassifierListPage = React.memo(ClassifierListPageUi);
