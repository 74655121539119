import { RiArrowRightCircleLine, RiListSettingsLine } from '@remixicon/react';
import { Button, Flex, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { useClassifierAttributesDeclarations } from 'entities/classifiers/classifierAttributeDeclarations/classifierAttributeDeclarations.model';
import { useClassifierItems } from 'entities/classifiers/classifiersItems/classifierItems.model';
import { setClassifierItems } from 'entities/classifiers/classifiersItems/classifierItems.store';
import {
	ClassifierDtoWithAttributeName,
	ClassifierItemDto,
	ItemDto,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { Chip, DropdownLink } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';
import { useClassifierLinkAttributesFilter } from './ClassifierLinkAttributesFilter.model';

type ItemDtoWidthStatus = ItemDto & { status: Element };

type TableColumnsType = ColumnsType<any>;

interface IClassifierNameFilterProps {
	classifier: ClassifierDtoWithAttributeName;
	list: Array<ClassifierItemDto>;
	cols: TableColumnsType;
	callback: React.Dispatch<React.SetStateAction<any[]>>;
	colsCallback: React.Dispatch<React.SetStateAction<TableColumnsType>>;
	filterCallback: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultCols: TableProps['columns'] = [
	{
		dataIndex: 'displayName',
		key: 'displayName',
		title: 'Наименование позиции справочника',
	},
	//TODO добавить колонку с именем атрибута и dataindex его айди, потом заполнять ее из values которые приходят в ответе
	{
		dataIndex: 'status',
		key: 'status',
		title: 'Статус',
	},
	{
		title: <RiListSettingsLine size={20} />,
		dataIndex: 'menu',
		key: 'menu',
		width: '50px',
	},
];

const ClassifierLinkAttributesFilterUi: React.FC<IClassifierNameFilterProps> = ({
	list,
	classifier,
	callback,
	colsCallback,
	cols,
	filterCallback,
}) => {
	const listRef = useRef<Array<ClassifierItemDto | ItemDtoWidthStatus>>([]);
	const colsRef = useRef<TableProps['columns']>([]);
	const [selectedButton, setSelectedButton] = useState<string | null>('all');
	const { getAttributesByIds, isLoading } = useClassifierLinkAttributesFilter();
	const { getClassifiers } = useClassifierItems();
	const { getAttributes } = useClassifierAttributesDeclarations();
	const dispatch = useAppDispatch();

	const { classifierItemId } = useParams();
	const navigate = useNavigate();

	const handleShowAll = async () => {
		filterCallback(false);
		await getClassifiers({ page: 1, limit: 10, parentItemId: classifier.id }).then((res) => {
			dispatch(setClassifierItems(res.data));
		});
		await getAttributes(classifier.id).then((res) => {
			if (Array.isArray(res)) {
				const newColumns = [
					{
						title: 'Статус',
						dataIndex: 'recStatus',
						key: 'recStatus',
					},
					...res.map((decl) => ({
						title: decl.attribute.displayName,
						dataIndex: decl.id,
						key: decl.id,
					})),
					{
						title: <RiListSettingsLine size={20} />,
						dataIndex: 'menu',
						key: 'menu',
						width: '50px',
					},
				];
				colsCallback(newColumns);
			}
		});
		setSelectedButton('all');
	};

	const handleFilter = async (id: string) => {
		filterCallback(true);
		colsCallback([
			{
				dataIndex: id,
				title: classifier.linkClassifierAttributes.find((item) => item.id === id)
					?.displayName,
			},
			...defaultCols,
		]);
		setSelectedButton(id);
		await getAttributesByIds(id).then((res) => {
			if (res) {
				callback(
					res.map((item) => ({
						...item,
						...item.values,
						key: item.id,
						status: (
							<Chip status={item.status.toLowerCase() as ChipStatus}>
								{chipTitle(item.status)}
							</Chip>
						),
						menu: (
							<AppDropdown
								items={[
									{
										key: `${randomString(5)}`,
										label: (
											<DropdownLink
												icon={<RiArrowRightCircleLine size={16} />}
												title="Переход в карточку записи"
												callback={() =>
													navigate(
														`/${routes.catalogs.main}/${item.catalogId}/${routes.catalogs.record}/${item.id}`
													)
												}
											/>
										),
									},
								]}
							/>
						),
					}))
				);
			} else {
				callback([]);
			}
		});
	};

	useEffect(() => {
		listRef.current = list;
		colsRef.current = cols;
	}, [list, cols]);

	useEffect(() => {
		handleShowAll();
	}, [classifierItemId]);

	return (
		<Flex gap={4}>
			<Button type={selectedButton === 'all' ? 'primary' : 'text'} onClick={handleShowAll}>
				Наполнение классификатора
			</Button>

			{classifier.linkClassifierAttributes?.map((attr) => (
				<Button
					key={attr.id}
					type={selectedButton === attr.id ? 'primary' : 'text'}
					onClick={() => handleFilter(attr.id)}
					loading={!!(selectedButton === attr.id && isLoading)}
				>
					{attr.displayName}
				</Button>
			))}
		</Flex>
	);
};

export const ClassifierLinkAttributesFilter = ClassifierLinkAttributesFilterUi;
