import { RiEditLine, RiListSettingsLine } from '@remixicon/react';
import { TablePaginationConfig, TableProps } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { DeleteClassifierRecord } from 'features/classifiers/ClassifierRecords/DeleteClassifierRecord';
import { chipTitle } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { useClassifierAttributesDeclarations } from 'entities/classifiers/classifierAttributeDeclarations/classifierAttributeDeclarations.model';
import { useClassifierItems } from 'entities/classifiers/classifiersItems/classifierItems.model';
import { setCurrentClassifierItem } from 'entities/classifiers/classifiersItems/classifierItems.store';
import { ClassifierItemDto, SliceClassifierItemDto } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { randomString } from 'shared/helpers';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Chip, DropdownLink } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';

const defaultPaginaton = { current: 1, pageSize: 10 };

type ItemValuesType = string | number | boolean;

export type ICatalogRecord = {
	id: string;
	recStatus: JSX.Element;
	menu: JSX.Element;
	key: string;
};
export type ICatalogRecords = Array<ICatalogRecord>;

export interface IRecordValue {
	[key: string]: {
		attributeName: string;
		value: ItemValuesType;
	};
}

export interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

//TODO перенести в хелпер и убрать из каталогов
export const useRecordsDtoToTableMapper = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { classifierAttributeDeclarations } = useClassifierAttributesDeclarations();

	const convertValues = (values: IRecordValue): IMappedRecordValue => {
		const result = {};

		Object.keys(values).forEach((id) => {
			const attribute = classifierAttributeDeclarations?.find((attr) => attr.id === id);
			if (attribute) {
				result[id] = CellValueParser(
					values[id]['value'],
					attribute.attribute.type as CellTypesEnum
				);
			}
		});

		return result;
	};

	const mapRecordsDtoToTable = (recordsDto: ClassifierItemDto[] | null): ICatalogRecords => {
		if (!recordsDto) return null;
		return recordsDto.map((item) => ({
			id: item.id,
			recStatus: (
				<Chip status={item.status.toLowerCase() as ChipStatus}>
					{chipTitle(item.status)}
				</Chip>
			),
			menu: (
				<AppDropdown
					items={[
						{
							key: `${item?.id}-${randomString(4)}`,
							label: <DeleteClassifierRecord recordId={item.id} />,
						},
						{
							key: `${item?.id}-${randomString(4)}`,
							label: (
								<DropdownLink
									icon={<RiEditLine size={16} />}
									title="Редактировать"
									callback={() => {
										dispatch(setCurrentClassifierItem(item));
										navigate(`${item.id}/${routes.classifiers.edit}`);
									}}
								/>
							),
						},
					]}
				/>
			),
			key: item.id,
			...(item.values && convertValues(item.values as IRecordValue)),
		}));
	};
	return {
		mapRecordsDtoToTable,
	};
};

export const useClassifierColumns = () => {
	const [columns, setColumns] = useState<TableProps<any>['columns']>(null);
	const [dataSource, setDataSource] = useState(null);
	const [pagination, setPagination] = useState<{
		current: number;
		pageSize: number;
		total?: number;
		showTotal?: (n: number) => string;
	}>(defaultPaginaton);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchedClassifierItems, setSearchedClassifierItems] = useState<
		Array<ClassifierItemDto>
	>([]);

	const { classifierAttributeDeclarations, loading } = useClassifierAttributesDeclarations();
	const { getClassifiers, loading: tableLoading } = useClassifierItems();
	const { classifierItemId } = useParams();
	const { mapRecordsDtoToTable } = useRecordsDtoToTableMapper();

	const { classifierItemsList } = useAppSelector(
		(store) => store.entities.classifiers.classifierItems
	);

	const handleTableChange = async (
		pagination?: TablePaginationConfig
	): Promise<SliceClassifierItemDto> => {
		const data = await getClassifiers(
			pagination.current,
			pagination.pageSize,
			classifierItemId
		);
		setPagination({
			...pagination,
			current: pagination.current,
			pageSize: pagination.pageSize,
			total: data?.meta?.total,
			showTotal: (n) => `Всего: ${n}`,
		});
		return data;
	};

	const passSearchValue = useCallback((v: string) => setSearchValue(v), []);
	const passSearchedClassifierItems = useCallback(
		(items: ClassifierItemDto[]) => setSearchedClassifierItems(items),
		[]
	);

	useEffect(() => {
		if (classifierAttributeDeclarations) {
			const newColumns = [
				{
					title: 'Статус',
					dataIndex: 'recStatus',
					key: 'recStatus',
				},
				...classifierAttributeDeclarations.map((decl) => ({
					title: decl.attribute.displayName,
					dataIndex: decl.id,
					key: decl.id,
				})),
				{
					title: <RiListSettingsLine size={20} />,
					dataIndex: 'menu',
					key: 'menu',
					width: '50px',
				},
			];
			setColumns(newColumns);
			handleTableChange({ current: 1, pageSize: 10 }).then((res) => {
				setDataSource(mapRecordsDtoToTable(res?.data));
			});
			setPagination(defaultPaginaton);
		}
	}, [classifierAttributeDeclarations]);

	useEffect(() => {
		handleTableChange({ current: 1, pageSize: 10 }).then((res) => {
			setDataSource(mapRecordsDtoToTable(res?.data));
		});
		setPagination(defaultPaginaton);
	}, [classifierItemId]);

	useEffect(() => {
		if (classifierItemsList && !loading) {
			setDataSource(
				mapRecordsDtoToTable(searchValue ? searchedClassifierItems : classifierItemsList)
			);
		}
	}, [classifierItemsList, loading, searchedClassifierItems, searchValue]);

	return {
		columns,
		dataSource,
		handleTableChange,
		pagination,
		loading,
		tableLoading,
		passSearchValue,
		passSearchedClassifierItems,
	};
};
