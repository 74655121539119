import { Radio, RadioChangeEvent } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useTypedTranslation } from '../../../hooks';

enum BooleanInputValues {
	Empty,
	True,
	False,
}

interface BooleanInputProps {
	value?: boolean | string | number | Array<string | number | boolean>;
	onChange?: (value: boolean) => void;
}

const mapBooleanInputValuesToPrimitiveValues: Record<BooleanInputValues, boolean | null> = {
	[BooleanInputValues.Empty]: null,
	[BooleanInputValues.True]: true,
	[BooleanInputValues.False]: false,
};

const BooleanInput = ({ value, onChange }: BooleanInputProps) => {
	const { t } = useTypedTranslation();

	const radioValue = useMemo(() => {
		const val = [ value ].flat()[0];

		if (val == null || val === '') {
			return BooleanInputValues.Empty;
		}

		return val ? BooleanInputValues.True : BooleanInputValues.False;
	}, [ value ]);

	const onChangeHandler = useCallback(
		({ target }: RadioChangeEvent) => onChange(mapBooleanInputValuesToPrimitiveValues[target.value]),
		[],
	);

	return (<Radio.Group value={radioValue} onChange={onChangeHandler}>
		<Radio value={BooleanInputValues.Empty}>
			{t(l => l.common.inputs.emptyValueLabel)}
		</Radio>
		<Radio value={BooleanInputValues.True}>
			{t(l => l.common.inputs.trueValueLabel)}
		</Radio>
		<Radio value={BooleanInputValues.False}>
			{t(l => l.common.inputs.falseValueLabel)}
		</Radio>
	</Radio.Group>);
};

export {
	BooleanInput,
};