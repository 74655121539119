import { useCallback } from 'react';
import { useTranslation as useTranslationOriginal } from 'react-i18next';
import { Localization } from '../translations';

function extractPropertyPath<T>(func: (x: T) => any): string {
	const funcString = func.toString();
	const match = funcString.match(/(\w+)\s*=>\s*\1\.(.+)/);

	if (match) {
		return match[2];
	} else {
		throw new Error('Не удалось извлечь путь свойств из функции');
	}
}

const useTypedTranslation = () => {
	const { t } = useTranslationOriginal();

	const fn = useCallback((selector: (l: Localization) => string) => {
		const path = extractPropertyPath(selector);
		return t(path);
	}, [ t ]);

	return {
		t: fn,
	};
};

export {
	useTypedTranslation,
};