import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { NotificationInstance } from 'antd/es/notification/interface';

export { mapValueForDto } from './mapValueForDto';

export const randomString = (length: number) => {
	let rnd = '';
	while (rnd.length < length) rnd += Math.random().toString(36).substring(2);
	return rnd.substring(0, 6);
};

export const capitalizeFirstLetter = (string: string): string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const truncateString = (str: string, maxLength: number): string => {
	if (str?.length > maxLength) {
		return str.slice(0, maxLength) + '...';
	} else {
		return str;
	}
};

export const getRandomInt = (min: number, max: number) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const errorHelper = (
	title: string,
	error: FetchBaseQueryError | SerializedError,
	api: NotificationInstance
) => {
	if ('data' in error) {
		api.error({ message: `${title}`, description: JSON.stringify(error.data) });
	} else if ('message' in error) {
		api.error({ message: `${title}`, description: error.message });
	} else if ('error' in error) {
		api.error({ message: `${title}`, description: error.error });
	} else {
		api.error({ message: `${title}`, description: JSON.stringify(error) });
	}
};

export const sortStrings = (a: string, b: string) => {
	if (a && b) {
		a = a.toUpperCase();
		b = b.toUpperCase();

		if (a < b) return -1;
		if (a > b) return 1;

		return 0;
	} else return 0;
};

export const removeDuplicates = <T, K extends keyof T>(arr: T[], prop: K): T[] => {
	const seen = new Set<T[K]>();
	return arr.filter((obj) => {
		if (seen.has(obj[prop])) {
			return false;
		} else {
			seen.add(obj[prop]);
			return true;
		}
	});
};

export const isValidDate = (dateString: string): boolean => {
	const date = new Date(dateString);

	return !isNaN(date.getTime());
};

export const isEmpty = (value: unknown) => {
	if (typeof value === 'object' && value !== null) {
		if (!Array.isArray(value) && !(value instanceof Map) && !(value instanceof Set)) {
			if (Object.keys(value).length) return false;
		}
	}
	if (Array.isArray(value) && value.length) return false;
	if (typeof value === 'object' && value !== null && value instanceof Map && value.size > 0)
		return false;
	if (typeof value === 'object' && value !== null && value instanceof Set && value.size > 0)
		return false;
	if (typeof value === 'string' && value.length) return false;
	return true;
};

export const integerToRGBA = (integer: number) => {
	const hex = integer.toString(16).padStart(8, '0');
	const r = parseInt(hex.slice(0, 2), 16);
	const g = parseInt(hex.slice(2, 4), 16);
	const b = parseInt(hex.slice(4, 6), 16);
	const a = parseInt(hex.slice(6, 8), 16) / 255;
	return `rgba(${r}, ${g}, ${b}, ${a})`;
};
