import { searchApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		getSearchResult: build.query<GetSearchResultApiResponse, GetSearchResultApiArg>({
			query: (queryArg) => ({
				url: `/v2/search/${queryArg.entityType}`,
				headers: { Authorization: queryArg.authorization },
				params: {
					from: queryArg['from'],
					textRequest: queryArg.textRequest,
					parentId: queryArg.parentId,
				},
			}),
		}),
		getAdvancedSearchResult: build.mutation<
			GetAdvancedSearchResultApiResponse,
			GetAdvancedSearchResultApiArg
		>({
			query: (queryArg) => ({
				url: `/v2/search/${queryArg.entityType}`,
				method: 'POST',
				body: queryArg.advancedSearchDtoRequest,
				headers: { Authorization: queryArg.authorization },
				params: { from: queryArg['from'], parentId: queryArg.parentId },
			}),
		}),
		getSearchResult1: build.query<GetSearchResult1ApiResponse, GetSearchResult1ApiArg>({
			query: (queryArg) => ({
				url: `/search/${queryArg.entityType}`,
				params: {
					from: queryArg['from'],
					textRequest: queryArg.textRequest,
					parentId: queryArg.parentId,
				},
			}),
		}),
		getAdvancedSearchResult1: build.mutation<
			GetAdvancedSearchResult1ApiResponse,
			GetAdvancedSearchResult1ApiArg
		>({
			query: (queryArg) => ({
				url: `/search/${queryArg.entityType}`,
				method: 'POST',
				body: queryArg.advancedSearchDtoRequest,
			}),
		}),
		getFullSystemSearchResult: build.query<
			GetFullSystemSearchResultApiResponse,
			GetFullSystemSearchResultApiArg
		>({
			query: (queryArg) => ({
				url: '/v2/search',
				headers: { Authorization: queryArg.authorization },
				params: { textRequest: queryArg.textRequest, from: queryArg['from'] },
			}),
		}),
		getFullSystemSearchResult1: build.query<
			GetFullSystemSearchResult1ApiResponse,
			GetFullSystemSearchResult1ApiArg
		>({
			query: (queryArg) => ({
				url: '/search/',
				params: { textRequest: queryArg.textRequest, from: queryArg['from'] },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as searchApi };
export type GetSearchResultApiResponse = /** status 200 OK */ object[];
export type GetSearchResultApiArg = {
	authorization?: string;
	entityType: string;
	from?: number;
	textRequest: string;
	parentId?: string;
};
export type GetAdvancedSearchResultApiResponse = /** status 200 OK */ object[];
export type GetAdvancedSearchResultApiArg = {
	authorization?: string;
	entityType: string;
	from?: number;
	parentId?: string;
	advancedSearchDtoRequest: AdvancedSearchDtoRequest;
};
export type GetSearchResult1ApiResponse = /** status 200 OK */ object[];
export type GetSearchResult1ApiArg = {
	entityType: string;
	from?: number;
	textRequest: string;
	parentId?: string;
};
export type GetAdvancedSearchResult1ApiResponse = /** status 200 OK */ object[];
export type GetAdvancedSearchResult1ApiArg = {
	entityType: string;
	advancedSearchDtoRequest: AdvancedSearchDtoRequest;
};
export type GetFullSystemSearchResultApiResponse = /** status 200 OK */ object[];
export type GetFullSystemSearchResultApiArg = {
	authorization?: string;
	textRequest: string;
	from?: number;
};
export type GetFullSystemSearchResult1ApiResponse = /** status 200 OK */ object[];
export type GetFullSystemSearchResult1ApiArg = {
	textRequest: string;
	from?: number;
};
export type Parameter = {
	parameter: string;
	value: string;
	comparisonParam:
		| 'EQUALS'
		| 'NOT_EQUALS'
		| 'EMPTY'
		| 'NOT_EMPTY'
		| 'BETWEEN'
		| 'MORE'
		| 'MORE_OR_EQUAL'
		| 'LESS'
		| 'LESS_OR_EQUAL'
		| 'CONTAINS'
		| 'NOT_CONTAINS';
};
export type AdvancedSearchDtoRequest = {
	modifier: 'OR' | 'AND';
	parameters: Parameter[];
};
export const {
	useGetSearchResultQuery,
	useLazyGetSearchResultQuery,
	useGetAdvancedSearchResultMutation,
	useGetSearchResult1Query,
	useLazyGetSearchResult1Query,
	useGetAdvancedSearchResult1Mutation,
	useGetFullSystemSearchResultQuery,
	useLazyGetFullSystemSearchResultQuery,
	useGetFullSystemSearchResult1Query,
	useLazyGetFullSystemSearchResult1Query,
} = injectedRtkApi;
