import { Localization } from './types';

const localization: Localization = {
	common: {
		buttons: {
			confirm: 'Confirm',
			cancel: 'Cancel',
			back: 'Back',
			delete: 'Delete',
			edit: 'Edit',
			columnsSetup: 'Column Setup',
			create: 'Create',
		},
		statuses: {
			normalized: 'Normalized',
			nonNormalized: 'Non-Normalized',
			blocked: 'Blocked',
			deleted: 'Deleted',
			dupe: 'Duplicate',
			error: 'Error',
		},
		defaultNames: {
			emptyName: '<Name not filled in>',
			status: 'Status',
			name: 'Name',
			type: 'Type',
			isMulti: 'Multiple',
		},
		search: {
			nothingFound: 'Nothing found for your query',
			checkRequest: 'Check if your query is entered correctly or try modifying it',
		},
		inputs: {
			emptyValueLabel: 'Empty',
			trueValueLabel: 'True',
			falseValueLabel: 'False',
		},
	},
	catalogs: {
		mainInfo: 'General Information',
		catalogs: 'Catalogs',
		catalog: 'Catalog',
		catalogInfo: 'Catalog Details',
		attributesDeclarations: {
			attributesDeclarationsErr: 'Error fetching the list of declarations!',
			addAttribute: 'Add Attribute',
			addAttributeDeclModal: 'To add an attribute, you need to select it from the list',
			mappingCreated: 'Mapping created',
			mappingUpdated: 'Mapping updated',
			mappingDeleted: 'Mapping deleted',
		},
		deduplication: {
			main: 'Position Deduplication',
			dupesGroupsErr: 'Error getting duplicate groups',
			dupesSearchResult: 'Duplicate Search Results',
			deleteDupesForSelectedItems: 'Delete duplicates for selected positions',
			makeDupesForSelectedItems: 'Mark selected positions as "Duplicate"',
			searchSaved: 'Search variant saved',
			searchCompleted: 'Search variant completed',
			updateSearchErr: 'Error updating duplicate search variant',
			importSearch: 'Search during import',
			addCatalog: 'Add Catalog',
			attributesList: 'Attributes used for duplicate search',
			addAttribute: 'Attributes used for duplicate search',
			selectAttribute: 'To add an attribute, you need to select it from the list',
			startDupeSearch: 'To add an attribute, you need to select it from the list',
			dupeSearchResult: 'View duplicate search results',
			variantN: 'Variant №',
			relaunchSearch: 'Restart Search',
			addNewVariant: 'Add new duplicate search variant',
			isOriginal: 'Is Original',
			setAsOriginal: 'Set as Original',
			position: 'Position',
			source: 'Source',
			unionAndDelete: 'Merge and delete duplicates',
			setStatus: 'Set status "Duplicate"',
			deleteDupes: 'Delete duplicates for the position',
		},
		groups: {
			noGroups: 'No reference groups',
			selectGroup: 'No reference groups',
			currCatalogErr: 'Error fetching the current catalog',
			catalogErr: 'Error fetching catalog',
			rootGroupErr: 'Error fetching root reference group!',
			listErr: 'Error fetching root reference group!',
			noGroupErr: 'You haven\'t added any reference groups yet',
			createNewGroup: 'To create a new group, click the "Add Reference Group" button',
			createNestedGroup: 'Add nested catalog',
			addNewCatalog: 'Add new catalog',
		},
		records: {
			recordsValues: 'Attribute Values',
			attributesSetup: 'Attribute Setup',
			editRecord: 'Edit Record',
			recordCard: 'Catalog Position Card',
			recordsRelationsErr: 'Error fetching records for "Relation" type attribute!',
			recordsListErr: 'Error fetching the list of records!',
			declarationsListErr: 'Error fetching declarations',
			noRecords: 'The catalog contains no positions',
			addRecordTooltip: 'To add a new position, click the "Add Reference Record" button',
		},
		restrictions: {
			restrictions: 'Restrictions',
			restrictionsTableErr: 'Error editing the table of allowed values',
			declarationsTableErr: 'Error editing the table of allowed values',
			addTable: 'Add allowed values table',
			noTableErr: 'You haven\'t created any restriction tables yet',
		},
	},
};

export default localization;