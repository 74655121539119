import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassifierItemDto, ClassifierItemTreeNode } from 'shared/api/generatedApi/mdmgApi';

export type ClassifierWithChildren = ClassifierItemDto & { children?: Array<ClassifierItemDto> };

interface IClassifiersTreeState {
	classifiersItemsTree: Array<ClassifierWithChildren>;
	currentClassifierTreeItem: ClassifierWithChildren;
}

const initialState: IClassifiersTreeState = {
	classifiersItemsTree: null,
	currentClassifierTreeItem: null,
};

const classifiersItemsTreeSlice = createSlice({
	name: 'classifiersItemsTree',
	initialState,
	reducers: {
		setClassifiersItemsTree(state, action: PayloadAction<ClassifierWithChildren[]>) {
			state.classifiersItemsTree = action.payload;
		},
		setCurrentClassifierTreeItem(state, action: PayloadAction<ClassifierWithChildren>) {
			state.currentClassifierTreeItem = action.payload;
		},
		editCurrentClassifierItem(
			state,
			{ payload }: PayloadAction<Partial<ClassifierWithChildren>>
		) {
			state.currentClassifierTreeItem = { ...state.currentClassifierTreeItem, ...payload };
		},
		addNewClassifierTreeItem(state, action: PayloadAction<ClassifierWithChildren>) {
			state.classifiersItemsTree = [
				...(state.classifiersItemsTree && state.classifiersItemsTree),
				action.payload,
			];
		},
		updateClassifierTreeItem(state, { payload }: PayloadAction<ClassifierWithChildren>) {
			state.classifiersItemsTree = state.classifiersItemsTree.map((item) => {
				if (item.id === payload.id) return payload;
				return item;
			});
		},
		updateClassifierTreeItemChild(
			state,
			{ payload }: PayloadAction<{ item: ClassifierWithChildren; parentId: string }>
		) {
			const updateCatalogGroupById = (
				arr: ClassifierItemTreeNode[],
				parentId: string,
				node: ClassifierItemTreeNode
			): ClassifierItemTreeNode[] => {
				return arr.map((item) => {
					if (item.id === parentId) {
						return {
							...item,
							parent: true,
							isLeaf: false,
							children: item.children ? [...item.children, node] : [node],
						};
					}

					if (item.children) {
						return {
							...item,
							children: updateCatalogGroupById(item.children, parentId, node),
						};
					}
					return item;
				});
			};

			state.classifiersItemsTree = updateCatalogGroupById(
				state.classifiersItemsTree,
				payload.parentId,
				payload.item
			);
		},
		deleteClassifierTreeItem(state, { payload }: PayloadAction<ClassifierWithChildren>) {
			state.classifiersItemsTree = state.classifiersItemsTree.filter(
				(catalog) => catalog.id !== payload.id
			);
		},
		removeCurrentClassifierTreeItem(state) {
			state.classifiersItemsTree = null;
		},
		deleteClassifierTreeItemById(state, { payload }: PayloadAction<string>) {
			const deleteCatalog = (
				tree: ClassifierItemTreeNode[],
				id: string
			): ClassifierItemTreeNode[] => {
				const data = tree.filter((item) => {
					if (item.id !== id && item.children?.length) {
						return deleteCatalog(item.children, id);
					}
					return item.id !== id;
				});

				return data.map((item) => {
					if (item.children) {
						return {
							...item,
							children: deleteCatalog(item.children, id),
						};
					}
					return item;
				});
			};

			state.classifiersItemsTree = deleteCatalog(state.classifiersItemsTree, payload);
		},
	},
});

export const {
	setClassifiersItemsTree,
	setCurrentClassifierTreeItem,
	editCurrentClassifierItem,
	addNewClassifierTreeItem,
	updateClassifierTreeItem,
	updateClassifierTreeItemChild,
	deleteClassifierTreeItem,
	removeCurrentClassifierTreeItem,
	deleteClassifierTreeItemById,
} = classifiersItemsTreeSlice.actions;

export const classifiersItemsTreeReducer = classifiersItemsTreeSlice.reducer;
