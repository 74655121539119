import { RiEditLine, RiLayoutGridLine, RiListSettingsLine, RiTable3 } from '@remixicon/react';
import { Button, Flex, Result, Skeleton, Table, TableProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { DeleteClassifier } from 'features/classifiers/deleteClassifier';
import { useSearchClassifiersGroup } from 'features/classifiers/searchClassifiersGroup';
import { useGetClassifiersGroups } from 'entities/classifiers/classifiersGroups/classifier.model';
import { setCurrentClassifier } from 'entities/classifiers/classifiersGroups/classifier.store';
import { ClassifierDtoWithAttributeName } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { isEmpty, truncateString } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { CardsSorter, DetailCard, DropdownLink, Hint, Placeholder } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';

const columns: TableProps['columns'] = [
	{
		title: 'Наименование',
		dataIndex: 'displayName',
		key: 'displayName',
	},
	{
		title: 'Классификационные связи',
		dataIndex: 'linkClassifierAttribute',
		key: 'linkClassifierAttribute',
	},
	{
		title: <RiListSettingsLine size={20} />,
		dataIndex: 'menu',
		key: 'menu',
		width: '50px',
	},
];

const ClassifiersWidgetUi: React.FC = () => {
	const [tableView, setTableView] = useState<boolean>(false);
	const [data, setData] = useState<Array<ClassifierDtoWithAttributeName>>([]);
	const { loading, error, classifiersGroups } = useGetClassifiersGroups();
	const { isLoading: isSearchLoading, searchValue } = useSearchClassifiersGroup();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const handleNavigate = (id: string) => {
		dispatch(setCurrentClassifier(classifiersGroups.find((item) => item.id === id)));
		navigate(id);
	};
	const handleSetTable = () => setTableView(true);
	const handleSetGrid = () => setTableView(false);

	useEffect(() => {
		if (classifiersGroups) setData(classifiersGroups);
	}, [classifiersGroups]);

	if (error)
		return (
			<Result
				title={'Ошибка при получении групп классификаторов!'}
				subTitle={`Текст ошибки: ${error}`}
			/>
		);

	return (
		<Flex vertical gap={24}>
			<Flex vertical gap={24}>
				<Flex vertical gap={12}>
					<Flex vertical gap={4}>
						<Flex gap={4} style={{ alignSelf: 'flex-end' }}>
							<Button
								icon={<RiLayoutGridLine />}
								type={tableView ? 'text' : 'primary'}
								onClick={handleSetGrid}
							/>
							<Button
								icon={<RiTable3 />}
								type={tableView ? 'primary' : 'text'}
								onClick={handleSetTable}
							/>
						</Flex>
						<Flex justify="space-between">
							<Typography.Title level={2}>Перечень классификаторов</Typography.Title>
							{!tableView && (
								<CardsSorter<ClassifierDtoWithAttributeName>
									defaultState={classifiersGroups}
									sorterProps={[
										{ sortType: 'alphabet', sortField: 'displayName' },
									]}
									callback={setData}
								/>
							)}
						</Flex>
					</Flex>

					<Flex gap={12} wrap="wrap">
						{loading || isSearchLoading ? (
							<>
								<Skeleton
									avatar
									active
									paragraph={{ rows: 3 }}
									style={{ maxWidth: 325 }}
								/>
								<Skeleton
									avatar
									active
									paragraph={{ rows: 3 }}
									style={{ maxWidth: 325 }}
								/>
								<Skeleton
									avatar
									active
									paragraph={{ rows: 3 }}
									style={{ maxWidth: 325 }}
								/>
							</>
						) : (
							(!data || data.length === 0) && (
								<Flex style={{ width: '100%' }} justify="center">
									<Placeholder
										title={
											searchValue
												? 'По вашему запросу ничего не найдено'
												: 'Вы не добавили еще ни одного классификатора'
										}
										subTitle={
											searchValue
												? 'Проверьте, правильно ли введен ваш запрос, или попробуйте его изменить'
												: 'Для создания нового классификатора нажмите на кнопку «Добавить классификатор»'
										}
										isSearchIcon={!!searchValue}
									/>
								</Flex>
							)
						)}
						{!tableView && data && (
							<>
								{data?.map((classifier) => (
									<DetailCard
										link={`${classifier.id}/${routes.classifiers.records}`}
										tooltip={classifier.description && classifier.description}
										key={classifier.id}
										secondSection={
											<Flex vertical gap={4}>
												<Typography.Title level={3}>
													<Hint
														title={
															classifier?.displayName &&
															classifier?.displayName?.length > 25
																? `${classifier.displayName}`
																: ''
														}
													>
														{classifier?.displayName
															? truncateString(
																	classifier?.displayName,
																	25
																)
															: '-'}
													</Hint>
												</Typography.Title>

												<Typography.Text>
													{`Связь: ${
														!isEmpty(
															classifier.linkClassifierAttributes
														)
															? classifier.linkClassifierAttributes
																	.map((item) => item.displayName)
																	.join(', ')
															: '-'
													}`}
												</Typography.Text>
											</Flex>
										}
										dropdownButtons={[
											{
												label: (
													<DropdownLink
														icon={<RiEditLine size={16} />}
														callback={() =>
															handleNavigate(classifier.id)
														}
														title="Редактировать"
													/>
												),
												key: '0',
											},
											{
												label: <DeleteClassifier id={classifier?.id} />,
												key: 'deleteClassifier',
											},
										]}
									/>
								))}
							</>
						)}

						{tableView && data && (
							<Table
								dataSource={data.map((classifier) => ({
									...classifier,
									menu: (
										<AppDropdown
											items={[
												{
													label: (
														<DropdownLink
															icon={<RiEditLine size={16} />}
															callback={() =>
																handleNavigate(classifier.id)
															}
															title="Редактировать"
														/>
													),
													key: '0',
												},
												{
													label: <DeleteClassifier id={classifier?.id} />,
													key: 'deleteClassifier',
												},
											]}
										/>
									),
								}))}
								columns={columns}
								loading={loading || isSearchLoading}
								style={{ width: '100%' }}
								onRow={(record) => {
									return {
										onClick: () => {
											handleNavigate(
												`${record.id}/${routes.classifiers.records}`
											);
										},
									};
								}}
							/>
						)}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export const ClassifiersListWidget = React.memo(ClassifiersWidgetUi);
