import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ItemStatusDto } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import { setClassifierAttributeDeclarationsList } from 'entities/classifiers/classifierAttributeDeclarations/attributeDeclarations.store';
import { useClassifierAttributesDeclarations } from 'entities/classifiers/classifierAttributeDeclarations/classifierAttributeDeclarations.model';
import {
	ClassifierItemDto,
	useLazyGetClassifierItemQuery,
	useUpdateClassifierItemMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { ItemValuesType, RecordType, Values } from 'shared/helpers/types';
import { useAppDispatch } from 'shared/hooks';
import { ChipStatusEnum } from 'shared/ui/components/Chip/chipStylehelper';
import { EditRecordStatus } from '../EditRecordStatus/EditRecordStatus';

export const useEditCatalogRecord = () => {
	const [recordsList, setRecordsList] = useState<RecordType[]>([]);
	const [record, setRecord] = useState<ClassifierItemDto | null>(null);
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const {
		classifierAttributeDeclarations,
		getAttributes,
		loading: isLoading,
	} = useClassifierAttributesDeclarations();

	const [getRecords, { error: recordsError, isFetching: isRecordsLoading }] =
		useLazyGetClassifierItemQuery();
	const [fetchUpdateItem] = useUpdateClassifierItemMutation();

	const { classifierGroupId, classifierItemId } = useParams();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const mapValues = (data?: ClassifierItemDto) => {
		const flattenedRecords: RecordType[] = Object.keys(data.values as Values).map((key) => {
			const record = (data.values as Values)[key];
			const editItem = (declarationId: string, value: ItemValuesType) => {
				const updatedValuesObject = {};
				Object.keys(data.values as Values).forEach((key) => {
					updatedValuesObject[key] = (data.values as Values)[key].value;
				});
				updatedValuesObject[declarationId] = value;
				updateItem(updatedValuesObject, data.status).then(() => {
					setRecord((prev) => ({
						...prev,
						values: {
							...prev.values,
							[declarationId]: {
								...prev.values[declarationId],
								value,
							},
						},
					}));
				});
			};

			return {
				id: key,
				attributeName: record?.attributeName,
				value: CellInputParser(
					record?.value,
					classifierAttributeDeclarations?.find((declaration) => declaration.id === key),
					editItem
				),
				key: key,
			};
		});

		const onStatusChange = (status: ChipStatusEnum) => {
			const updatedValuesObject = {};
			Object.keys(data.values as Values).forEach((key) => {
				updatedValuesObject[key] = (data.values as Values)[key].value;
			});
			updateItem(updatedValuesObject, status.toUpperCase() as ItemStatusDto).then(() => {
				setRecord((prev) => ({
					...prev,
					status: status.toUpperCase() as ItemStatusDto,
				}));
			});
		};
		flattenedRecords.unshift({
			attributeName: 'Статус позиции',
			id: 'status',
			key: 'status',
			value: <EditRecordStatus data={data} onChange={onStatusChange} />,
		});
		setRecordsList(flattenedRecords);
	};

	const updateItem = async (values: { [key: string]: object }, status: ItemStatusDto) => {
		await fetchUpdateItem({
			id: classifierItemId,
			classifierId: classifierGroupId,
			updateClassifierItemRequest: {
				status,
				values,
			},
		})
			.unwrap()
			.catch((err) => {
				errorHelper('Ошибка при редактировании записи', err, notification);
			});
	};

	useEffect(() => {
		if (record) {
			mapValues(record);
		}
	}, [record]);

	useEffect(() => {
		if (!classifierAttributeDeclarations) {
			getAttributes(classifierGroupId)
				.then((res) => {
					if (Array.isArray(res)) {
						dispatch(setClassifierAttributeDeclarationsList(res));
					} else {
						errorHelper(
							'Ошибка при получении деклараций атрибутов классификатора',
							res,
							notification
						);
					}
				})
				.catch((err) => {
					errorHelper(
						'Ошибка при получении деклараций атрибутов классификатора',
						err,
						notification
					);
				});
		} else {
			const getData = async () => {
				const data = await getRecords({ id: classifierItemId });
				if ('error' in data) {
					errorHelper('Ошибка при получении списка атрибутов', data.error, notification);
				}
				if ('data' in data) {
					setRecord(data.data);
				}
			};

			getData();
		}
	}, [classifierAttributeDeclarations]);

	useEffect(() => {
		if (!recordsError) setError(null);
	}, [recordsError]);

	return {
		recordsList,
		attributeDeclarations: classifierAttributeDeclarations,
		loading: isRecordsLoading || isLoading,
		error,
	};
};
