import { Col, Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Transactions } from 'widgets/Transactions';
import { EditCatalogRecord } from 'features/catalogs/CatalogRecords/EditCatalogRecord';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { routes } from 'shared/config';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

export const RecordDetailPageUi: React.FC = () => {
	const { t } = useTypedTranslation();

	const dispatch = useAppDispatch();
	const { selectedCatalog } = useCatalogGroups();
	const navigate = useNavigate();

	const { recordId, catalogGroupId } = useParams();

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: t(l => l.catalogs.records.recordsValues),
			children: <EditCatalogRecord />,
		},
	];

	useEffect(() => {
		if (selectedCatalog) {
			dispatch(
				setBreadcrumbs({
					displayName: t(l => l.catalogs.catalogs),
					url: `${routes.catalogs.main}`,
					child: {
						displayName: `${selectedCatalog.displayName}`,
						url: `${routes.catalogs.main}/${selectedCatalog.id}`,
						child: {
							displayName: t(l => l.catalogs.records.editRecord),
							url: `${routes.catalogs.main}/${selectedCatalog.id}/record/${recordId}`,
						},
					},
				})
			);
		}
	}, [selectedCatalog, recordId]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.catalogs.main}/${catalogGroupId}`);
	};

	return (
		<>
			<Flex vertical gap={12} style={{ paddingBottom: 65 }}>
				<Flex justify="space-between">
					<Flex vertical gap={12} justify="start">
						<Typography.Title level={1}>
							{t(l => l.catalogs.records.recordCard)}
						</Typography.Title>
					</Flex>

					<Transactions />
				</Flex>
				<Col xl={18} xs={24} sm={24} md={24} lg={18}>
					<Tabs items={items} destroyInactiveTabPane />
				</Col>
			</Flex>

			<DetailFooter customHandleBack={handleBackClick} />
		</>
	);
};

export const RecordDetailPage = React.memo(RecordDetailPageUi);
