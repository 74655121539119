import { Flex, Table, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ClassifierLinkAttributesFilter } from 'features/classifiers/ClassifierLinkAttributesFilter';
import { AddClassifierRecord } from 'features/classifiers/ClassifierRecords/AddClassifierRecord';
import {
	SearchInnerClassifiersItems,
	useSearchInnerClassifiersItems,
} from 'features/classifiers/SearchInnerClassifiersItems';
import { ClassifierItemDto, ItemDto } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useAppSelector } from 'shared/hooks';
import { useClassifierColumns } from './classifierItemsTable.model';

const ClassifierItemsTableUi = () => {
	const [data, setData] = useState([]);
	const [cols, setCols] = useState<TableProps<ClassifierItemDto | ItemDto>['columns']>([]);
	const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
	const { classifierItemId } = useParams();
	const {
		columns,
		dataSource,
		loading: colsLoading,
		handleTableChange,
		pagination,
		passSearchValue,
		passSearchedClassifierItems,
	} = useClassifierColumns();

	const { searchValue, searchedClassifierItems } = useSearchInnerClassifiersItems();

	useEffect(() => {
		passSearchValue(searchValue);
		passSearchedClassifierItems(searchedClassifierItems);
	}, [searchValue, searchedClassifierItems]);

	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);

	const navigate = useNavigate();

	useEffect(() => {
		setData(dataSource);
	}, [dataSource]);

	useEffect(() => {
		setCols(columns);
	}, [columns]);

	useEffect(() => {
		setCols(columns);
	}, [columns]);

	return (
		<Flex vertical gap={24}>
			{classifierItemId && <SearchInnerClassifiersItems />}
			<AddClassifierRecord parentId={classifierItemId} isButton />
			<ClassifierLinkAttributesFilter
				cols={cols}
				classifier={currentClassifierGroup}
				list={dataSource}
				colsCallback={setCols}
				callback={setData}
				filterCallback={setIsFilterActive}
			/>
			<Table<ClassifierItemDto | ItemDto>
				loading={colsLoading}
				columns={cols}
				dataSource={data}
				onChange={
					!isFilterActive ? (pagination) => handleTableChange(pagination) : undefined
				}
				pagination={!isFilterActive ? pagination : { defaultCurrent: 1, pageSize: 10 }}
				onRow={(r) => ({
					onClick: () =>
						!isFilterActive
							? navigate(
									`${window.location.pathname}/${r.id}/${routes.classifiers.edit}`
								)
							: null,
				})}
			/>
		</Flex>
	);
};

export const ClassifierItemsTable = React.memo(ClassifierItemsTableUi);
